import React                   from 'react';
import { graphql }             from 'gatsby';
import Img                     from 'gatsby-image';

import Heading     from '@interness/web-core/src/components/text/Heading/Heading';
import Spacer      from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Wrapper     from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import CallToAction
                   from '@interness/web-core/src/components/modules/CallToAction/CallToAction';
import TeamMembers from '@interness/web-core/src/components/modules/TeamMembers';
import Carousel    from '@interness/web-core/src/components/media/Carousel/Carousel';

function AboutPage(props) {
  return (
    <>
      <Carousel>
        {props.data.headerImages.media.map(image => {
          return (
            <Img key={image.file.localFile.name} fluid={image.file.localFile.childImageSharp.fluid}
                 alt={image.file.localFile.name}/>
          );
        })}
      </Carousel>
      <Wrapper>
        <Spacer/>
        <Heading subtitle={'Goldschmiede J. Peter Rust Goldschmiedetradition seit 1968'}>Über uns</Heading>
        <Spacer/>
        <TeamMembers layout={'slider'} />
        <Spacer/>
        <section>
          <h3>Tradition und Leidenschaft</h3>
          <p>
            J.Peter Rust, der temperamentvolle Essener Goldschmied, setzt, unterstützt von seiner Frau Brigitte, die unternehmerische Tradition seiner Familie seit 1968 im eigenen Geschäft, fort.
          </p>
          <p>Der Weg der alteingesessenen Essener spannt sich von der großväterlichen Apotheke zur väterlichen Goldschmiede- Werkstatt in der Holsterhauserstraße / Ecke Feuerbachstraße 1 hin zum heutigen Geschäft in der Gemarkenstr. 55 in Essen Holsterhausen.
            Als Händler, Spezialist und versierter Handwerker ist J.Peter Rust heute eine namhafte Adresse für Luxus Uhrenmarken und exquisite Schmuckstücke.
            Den schönsten Edelsteinen hat er in seinem Atelier zur dauerhaft funkelnden Prachtentfaltung in silberner oder goldener Fassung verholfen.
            Aber was fasziniert den Goldschmied an der Arbeit mit den edlen Metallen? -- Nun, schließlich ist Gold -- neben Frau Brigitte -- die denkbar beständigste Währung, in der sich das Leben wiegen und berechnen lässt.
            Die Leidenschaft für traumhaft schönen Schmuck und feine Uhren, die J.Peter Rust im beruflichen Leben innewohnt, ist im privaten Umfeld die Vorliebe für den Motorsport.
            Bei windschnellen Rennfahrten auf den Serpentinenstraßen Mallorcas, die J. Peter Rust mit dem eigenen Jaguar-Oldtimer absolviert, verbindet er immer wieder das Sportliche mit einem Hauch von Nostalgie.</p>
        </section>
        <Spacer />
        <div style={{ textAlign: 'center' }}>
          <CallToAction/>
        </div>
        <Spacer/>
      </Wrapper>
    </>
  )
}

export default AboutPage;

export const query = graphql`
  query {
    headerImages: directusMediaCollection(name: {eq: "about-header"}) {
      ...MediaCollectionHeaderImages
    }
  }
`;